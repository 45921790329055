<template>
  <header class="header parallax ">
    <div class="d1"></div>
    <div class="d2"></div>
    <div class="d3"></div>
    <div class="d4"></div>
    <div class="name">
      <div class="blurred-box" />
      <div class="wrapper-name ">
        <AnimateOnVisible name="fadeDown" ::duration="1">
          <h1>{{ user.name }}</h1>
        </AnimateOnVisible>
        <hr />
        <AnimateOnVisible name="fadeUp" ::duration="1">
          <p>{{ user.status }}</p>
        </AnimateOnVisible>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'LandingPage',
  props: ['user'],
};
</script>

<style scoped lang="scss">
@import '@/styles/constants.scss';

.header {
  padding: 20px;
  background-color: map-get($colors, dark);
}

.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #2f2f2f;
  background-size: cover;
  height: 100%;
}
.name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  z-index: 400;

  .wrapper-name {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
  }

  .blurred-box {
    background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #2f2f2f, rgba(255, 0, 0, 0));
    width: 800px;
    height: 275px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    filter: blur(10px);
    z-index: 10;
    opacity: 0.9;
  }

  .blurred-box:after {
    content: '';
    width: 800px;
    height: 275px;
    left: -25px;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.05);
    z-index: 10;
  }

  h1 {
    font-size: 2.3rem;
    padding: 2px 10px;
    text-align: center;
    text-transform: uppercase;
    color: whitesmoke;
  }

  p {
    font-size: 1.5rem;
    text-align: center;
    margin: 5px auto;
    color: whitesmoke;
  }

  hr {
    border: 1px solid whitesmoke;
    width: 56%;
  }
}

@media (min-width: #{map-get($breakpoints, small)}) {
  .name {
    .wrapper-name {
      width: 55%;
      z-index: 10;
    }
    h1 {
      font-size: 2.8rem;
      padding: 4% 8%;
    }
    p {
      font-size: 2rem;
    }
  }
}
@media (min-width: #{map-get($breakpoints, medium)}) {
  .name {
    .wrapper-name {
      width: 450px;
      z-index: 10;
    }
    h1 {
      font-size: 4rem;
      padding: 4% 10%;
    }
    p {
      font-size: 2.5rem;
    }
  }
}
@media only screen and (max-device-width: 1024px) {
  .parallax {
    background-attachment: scroll;
  }
}

@media (min-width: #{map-get($breakpoints, medium)}) {
  .d1 {
    position: absolute;
    background-image: url('../../public/photo.webp');
    background-size: 2700px 1500px;

    height: 32vh;
    width: 15vw;

    background-position: 0 50%;

    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.8);
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    animation: dd1 10s 1, dd12 10s 1;
    animation-delay: 4s, 14s;
  }
  .d2 {
    position: absolute;
    background-image: url('../../public/photo.webp');
    background-size: 2700px 1500px;

    height: 50vh;
    width: 25vw;

    background-position: -10vw 50%;
    left: 10vw;

    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    animation: dd2 10s 2;
    animation-delay: 4s;
  }
  .d3 {
    position: absolute;
    background-image: url('../../public/photo.webp');
    background-size: 2700px 1500px;
    overflow: hidden;

    height: 100vh;
    width: 40vw;
    left: 25vw;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.8);
    background-position: -35vw 50%;

    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    animation: dd3 10s 2;
    animation-delay: 4s;
  }
  .d4 {
    position: absolute;
    overflow: hidden;
    background-image: url('../../public/photo.webp');
    background-size: 2700px 1500px;

    height: 80vh;
    width: 25vw;
    left: 60vw;
    background-position: -70vw 50%;

    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    animation: dd4 10s 2;
    animation-delay: 4s;
  }

  @keyframes dd1 {
    0% {
    }
    50% {
      width: 95vw;
    }
  }
  @keyframes dd12 {
    0% {
    }
    50% {
      background-position: calc(0vw - 40px) 50%;
    }
  }
  @keyframes dd2 {
    0% {
    }
    50% {
      background-position: calc(-10vw - 40px) 50%;
    }
  }
  @keyframes dd3 {
    0% {
    }
    50% {
      background-position: calc(-35vw - 40px) 50%;
    }
  }
  @keyframes dd4 {
    0% {
    }
    50% {
      background-position: calc(-70vw - 40px) 50%;
    }
  }
}
</style>
